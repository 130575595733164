// SuccessPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Leaves } from '../Products/Components';

const SuccessPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center min-h-screen bg-gold p-8">
      <div className="relative">
        <Leaves />
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-neris-semibold text-brown mb-4">
          Thank You for Your Subscription!
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-8">
          Your subscription has been successfully activated. We can't wait for you to enjoy our premium coffee!
        </p>
        <Link
          to="/"
          className="px-6 py-2 bg-green text-gold font-neris-light rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;