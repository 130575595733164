// src/stores/SubscriptionStore.ts

import { makeAutoObservable, action, runInAction } from 'mobx';
import { format, addDays } from 'date-fns';
import API from '../client'; // Adjust the import path as necessary

export interface Address {
  fullName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateOrRegion: string;
  postalCode: string;
  country: string;
}

class SubscriptionStore {
  startDate: string = this.getDefaultStartDate();
  frequency: '2' | '4' | 'monthly' | '6' | '8' = '6'; // Default frequency in weeks
  subscriptionOption: '2x500g' | '5x500g' = '2x500g'; // Default subscription option
  accessCode: string = ''; // Access code for special pricing
  address: Address = {
    fullName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateOrRegion: '',
    postalCode: '',
    country: '',
  };
  isLoading: boolean = false;
  message?: string;

  constructor() {
    makeAutoObservable(this);
  }

  getDefaultStartDate() {
    const date = addDays(new Date(), 3); // Three days from today
    return format(date, 'yyyy-MM-dd');
  }

  setStartDate = action((date: string) => {
    this.startDate = date;
  });

  setFrequency = action((frequency: '2' | '4' | 'monthly' | '6' | '8') => {
    this.frequency = frequency;
  });

  setSubscriptionOption = action((option: '2x500g' | '5x500g') => {
    this.subscriptionOption = option;
  });

  setAccessCode = action((code: string) => {
    this.accessCode = code;
  });

  setAddress = action((address: Address) => {
    this.address = address;
  });

  createSubscription = action(async () => {
    this.isLoading = true;
    this.message = undefined;
    try {
      // Create the subscription and get custom_id
      const response = await API.post('/api/merchandise/create-subscription', {
        startDate: this.startDate,
        frequency: this.frequency,
        subscriptionOption: this.subscriptionOption,
        accessCode: this.accessCode, // Include the access code
      });
      const { id: sessionId, custom_id } = response.data;

      // Save the shipping address with custom_id
      await API.post('/api/merchandise/shipping_addresses/', {
        full_name: this.address.fullName,
        address_line_1: this.address.addressLine1,
        address_line_2: this.address.addressLine2,
        city: this.address.city,
        state_or_region: this.address.stateOrRegion,
        postal_code: this.address.postalCode,
        country: this.address.country,
        custom_id: custom_id,
      });

      runInAction(() => {
        this.isLoading = false;
      });

      return { sessionId };
    } catch (error: any) {
      runInAction(() => {
        this.isLoading = false;
        this.message = error.response?.data?.error || 'An error occurred. Please try again.';
      });
      throw error;
    }
  });
}

const subscriptionStore = new SubscriptionStore();
export default subscriptionStore;