// src/components/Products/MountainSunrise.tsx

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ProductPrice, { Leaves, ProductDescription, ProductImages, QuantitySelector, WeightSelector } from './Components';
import { cartStore } from '../../stores/CartStore';
import { useNavigate } from 'react-router-dom';
import subscriptionStore from '../../stores/SubscriptionStore';
import CartPreview from '../Checkout/Cart/CartPreview';

const MountainSunrise = observer(() => {
  const [quantity, setQuantity] = useState(1); // Local state for handling quantity
  const [purchaseType, setPurchaseType] = useState<'one-time' | 'subscription'>('one-time');
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = useState<'2.2lbs' | '5.5lbs'>('2.2lbs');
  const [frequency, setFrequency] = useState<'2' | '4' | 'monthly' | '6' | '8'>('6');
  const navigate = useNavigate();

  const handleWeightChange = (weight: number) => {
    // Handle weight change if necessary
  };

  const handleAddToCartClick = () => {
    cartStore.addToCart({
      id: 'mountain-sunrise',
      name: 'Mountain Sunrise Coffee',
      price: cartStore.calculatePriceBasedOnWeight(360),
      quantity: quantity,
      weight: 360,
      imageUrl: 'https://silver-stream-bucket.s3.amazonaws.com/puch.svg',
    });
    setQuantity(1); // Reset quantity after adding to cart
  };

  const handleSubscribeClick = () => {
    // Update the subscription store
    subscriptionStore.setSubscriptionOption(selectedSubscriptionOption === '2.2lbs' ? '2x500g' : '5x500g');
    subscriptionStore.setFrequency(frequency);
    // Navigate to the subscription page
    navigate('/subscription');
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  // Subscription Options to match WeightSelector styling
  const SubscriptionOptionSelector: React.FC = () => {
    const options = [
      {
        label: '2.2 lbs (Two 1.1 lb bags for freshness)',
        value: '2.2lbs',
        description: 'Two 1.1 lb bags for freshness',
      },
      {
        label: '5.5 lbs (Five 1.1 lb bags for freshness)',
        value: '5.5lbs',
        description: 'Five 1.1 lb bags for freshness',
      },
    ];

    return (
      <div className="flex flex-col space-y-2 text-brown font-neris-semibold">
        <span>Subscription Options</span>
        <div className="flex items-center space-x-4">
          {options.map((option) => (
            <div key={option.value} className="flex items-center space-x-2">
              <input
                type="radio"
                name="subscriptionOption"
                id={option.value}
                value={option.value}
                className="hidden"
                checked={selectedSubscriptionOption === option.value}
                onChange={() => setSelectedSubscriptionOption(option.value as any)}
              />
              <label
                htmlFor={option.value}
                className={`p-2 rounded-full ${
                  selectedSubscriptionOption === option.value ? 'bg-brown' : 'bg-transparent border border-brown'
                } cursor-pointer`}
              ></label>
              <label htmlFor={option.value} className="cursor-pointer">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Delivery Frequency to match QuantitySelector styling
  const DeliveryFrequencySelector: React.FC = () => {
    const frequencies: ('2' | '4' | 'monthly' | '6' | '8')[] = ['2', '4', 'monthly', '6', '8'];

    const increaseFrequency = () => {
      const currentIndex = frequencies.indexOf(frequency);
      const nextIndex = (currentIndex + 1) % frequencies.length;
      setFrequency(frequencies[nextIndex]);
    };

    const decreaseFrequency = () => {
      const currentIndex = frequencies.indexOf(frequency);
      const prevIndex = (currentIndex - 1 + frequencies.length) % frequencies.length;
      setFrequency(frequencies[prevIndex]);
    };

    return (
      <div className="flex flex-col items-start space-y-2 text-brown font-neris-semibold">
        <span>Delivery Frequency</span>
        <div className="flex items-center space-x-2 bg-brown rounded-full">
          <button
            onClick={decreaseFrequency}
            className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full"
          >
            -
          </button>
          <span className="font-neris-semibold text-gold px-2 w-full text-center">
            {frequency === 'monthly' ? 'Every Month' : `Every ${frequency} Weeks`}
          </span>
          <button
            onClick={increaseFrequency}
            className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full"
          >
            +
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-gold justify-center space-y-6 p-10 relative">
      <div className="absolute inset-0 z-0 overflow-hidden">
        <img
          src="https://silver-stream-bucket.s3.amazonaws.com/product_cute_1.svg"
          alt="coffee plant drawing"
          className="absolute md:mb-0 bottom-[-30%] w-[50%] h-auto lg:-right-20 md:-right-30 sm:-right-10"
        />
      </div>

      <div className="flex flex-col md:flex-row space-x-0 md:space-x-20 w-full md:w-3/5 mx-auto space-y-6 md:space-y-0">
        <ProductImages />

        <div className="flex w-full md:w-4/5 relative z-20">
          <div className="flex flex-col space-y-4 w-full">
            <ProductDescription />
            <ProductPrice
              price={
                purchaseType === 'one-time'
                  ? 29
                  : selectedSubscriptionOption === '2.2lbs'
                  ? 55
                  : 100
              }
            />

            {/* Purchase Type Toggle */}
            <div className="flex items-center space-x-4 mt-4">
              <button
                className={`px-4 py-2 rounded-full ${
                  purchaseType === 'one-time' ? 'bg-brown text-gold' : 'bg-transparent border border-brown text-brown'
                }`}
                onClick={() => setPurchaseType('one-time')}
              >
                One-Time Purchase
              </button>
              <button
                className={`px-4 py-2 rounded-full ${
                  purchaseType === 'subscription' ? 'bg-brown text-gold' : 'bg-transparent border border-brown text-brown'
                }`}
                onClick={() => setPurchaseType('subscription')}
              >
                Subscribe
              </button>
            </div>

            {/* Subscription Options */}
            {purchaseType === 'subscription' && (
              <>
                <SubscriptionOptionSelector />
                <DeliveryFrequencySelector />
              </>
            )}

            {/* Quantity Selector */}
            {purchaseType === 'one-time' && (
              <>
                <WeightSelector selectedWeight={360} onWeightChange={handleWeightChange} />
                <QuantitySelector
                  quantity={quantity}
                  increaseQuantity={handleIncreaseQuantity}
                  decreaseQuantity={handleDecreaseQuantity}
                />
              </>
            )}

            {/* Action Button */}
            <button
              onClick={purchaseType === 'one-time' ? handleAddToCartClick : handleSubscribeClick}
              className="px-6 py-2 bg-green text-gold font-neris-light rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown z-1"
            >
              {purchaseType === 'one-time' ? 'Add to Cart' : 'Subscribe Now'}
            </button>

            {/* Cart Preview */}
            {purchaseType === 'one-time' && <CartPreview />}
          </div>

          <Leaves />
        </div>
      </div>
    </div>
  );
});

export default MountainSunrise;