// src/stores/PaymentStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import API from '../client';
import { AxiosError } from 'axios';

interface PaymentIntentResponse {
  clientSecret?: string;
  error?: string;
}

export type Address = {
  fullName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateOrRegion: string;
  postalCode: string;
  country: string;
};

interface UpdatePaymentStatusRequest {
  payment_intent_id: string;
  payment_intent_client_secret: string;
  redirect_status: string;
}

interface UpdatePaymentStatusResponse {
  message: string;
  // Include any other properties that you expect in the response
}



class PaymentStore {
  clientSecret: string | undefined;
  isLoading: boolean = false;
  message: string | undefined;
  stripe: Stripe | null = null;
  address: Address = {
    fullName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateOrRegion: '',
    postalCode: '',
    country: ''
  };
  
  constructor() {
    makeAutoObservable(this);
    this.initializeStripe();
  }

  setAddress(address: Address) {
    this.address = address;
  } 

  private async initializeStripe() {
    this.isLoading = true;
    try {
      const stripeClient = await loadStripe(process.env.REACT_APP_PUBLIC_KEY || '');
      runInAction(() => {
        this.stripe = stripeClient;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.message = 'Failed to load payment services.';
        this.isLoading = false;
      });
      console.error('Error initializing Stripe:', error);
    }
  }

  setClientSecret(secret : string | undefined) {
    runInAction(() => {
      this.clientSecret = secret;
    })
  }


  fetchClientSecret = async (cost: number) => {
    this.isLoading = true;
    const response = await API.post<PaymentIntentResponse>('/api/create-payment-intent/', { cost });
    runInAction(() => {
      this.clientSecret = response.data.clientSecret;
      this.isLoading = false;
    });
  };

  confirmPayment = async (elements: StripeElements) => {
    this.isLoading = true;
    if (!this.stripe || !elements) {
        this.isLoading = false;
        return;
    }
    runInAction(() => {
      this.saveAddressWithPaymentIntent();
    })
    const { error } = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
            return_url: process.env.REACT_APP_BASE_URL + 'payment-processed',
        },
    });
    runInAction(() => {
        this.isLoading = false;
        if (error) {
          console.log(error)
            this.message = error.message;
        } 
    });
  };
  
  saveAddressWithPaymentIntent = async () => {
    this.isLoading = true;
    try {
        const response = await API.post('/api/merchandise/shipping_addresses/', 
        {
            full_name: this.address.fullName,
            address_line_1: this.address.addressLine1,
            address_line_2: this.address.addressLine2,
            city: this.address.city,
            state_or_region: this.address.stateOrRegion,
            postal_code: this.address.postalCode,
            country: this.address.country,
            payment_intent_id_secret: this.clientSecret,
        });
        runInAction(() => {
            this.isLoading = false;
            console.log('Address saved with payment intent:', response.data);
        });
    } catch (error) {
        runInAction(() => {
            this.isLoading = false;
            this.message = (error as any).response?.data?.detail || (error as any).message || 'Error saving address';
        });
    }
}

  updatePaymentStatusInDatabase = async (
    paymentIntentId: string, 
    paymentIntentClientSecret: string, 
    redirectStatus: string
  ): Promise<void> => {
    try {
      console.log('we made it')
      const response = await API.post<UpdatePaymentStatusResponse>('/api/merchandise/update-payment-status', {
        payment_intent_id: paymentIntentId,
        payment_intent_client_secret: paymentIntentClientSecret,
        redirect_status: redirectStatus
      } as UpdatePaymentStatusRequest);
      
      console.log('Database update response:', response.data);
    } catch (error) {
      const err = error as AxiosError;
      console.error('Failed to update payment status:', err.response?.data || err.message);
    }
  };

}

const paymentStore = new PaymentStore();
export default paymentStore;
