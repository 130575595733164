// src/components/SubscriptionPage/SubscriptionPage.tsx

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import subscriptionStore from '../../stores/SubscriptionStore';
import { loadStripe } from '@stripe/stripe-js';
import { Leaves } from '../Products/Components'; // Adjusted import
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, addDays, parse, startOfDay } from 'date-fns';
import { Address } from '../../stores/SubscriptionStore';

// For toast notifications
import { toast } from 'react-toastify';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY || '');

const SubscriptionPage: React.FC = observer(() => {
  const [address, setAddress] = useState(subscriptionStore.address);
  const [isFormValid, setIsFormValid] = useState(false);
  const minStartDate = startOfDay(addDays(new Date(), 3)); // Start of day, three days from now
  const [startDate, setStartDate] = useState<Date | null>(minStartDate);

  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = useState<'2.2lbs' | '5.5lbs'>(
    subscriptionStore.subscriptionOption === '2x500g' ? '2.2lbs' : '5.5lbs'
  );
  const [frequency, setFrequency] = useState<'2' | '4' | 'monthly' | '6' | '8'>(subscriptionStore.frequency);

  const [showAccessCodeField, setShowAccessCodeField] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [originalPrices] = useState({ '2.2lbs': 55, '5.5lbs': 100 });
  const [discountedPrices] = useState({ '2.2lbs': 50, '5.5lbs': 90 });

  // State variables for validation
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});
  const [dateTouched, setDateTouched] = useState(false);
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);

  const handleDateChange = (date: Date | null) => {
    setDateTouched(true);
    if (date) {
      setStartDate(date);
      subscriptionStore.setStartDate(format(date, 'yyyy-MM-dd'));
    } else {
      setStartDate(null);
      subscriptionStore.setStartDate('');
    }
  };

  const handleInputChange = (field: keyof Address, value: string) => {
    setAddress({ ...address, [field]: value });
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  const validateForm = () => {
    const requiredFields = ['fullName', 'addressLine1', 'city', 'stateOrRegion', 'postalCode', 'country'];
    let isValidFields = true;
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      if (!address[field as keyof Address] || address[field as keyof Address]?.trim() === '') {
        isValidFields = false;
        newErrors[field] = 'This field is required';
      } else {
        delete newErrors[field];
      }
    });

    const selectedDate = startOfDay(parse(subscriptionStore.startDate, 'yyyy-MM-dd', new Date()));
    let isValidDate = true;

    if (!subscriptionStore.startDate || selectedDate < minStartDate) {
      isValidDate = false;
      newErrors['startDate'] = 'Start date must be at least three days from today';
    } else {
      delete newErrors['startDate'];
    }

    setErrors(newErrors);
    const formIsValid = isValidFields && isValidDate;
    setIsFormValid(formIsValid);
    return formIsValid;
  };

  useEffect(() => {
    validateForm();
  }, [address, subscriptionStore.startDate]);

  const handleSubscribe = async () => {
    const formIsValid = validateForm();
    setFormSubmitAttempted(true);

    if (!formIsValid) {
      toast.error('Please fix the errors in the form before submitting.');
      return;
    }

    // Proceed with subscription creation
    subscriptionStore.setAddress(address);
    subscriptionStore.setSubscriptionOption(
      selectedSubscriptionOption === '2.2lbs' ? '2x500g' : '5x500g'
    );
    subscriptionStore.setFrequency(frequency);
    try {
      const stripe = await stripePromise;
      const { sessionId } = await subscriptionStore.createSubscription();
      if (stripe) {
        await stripe.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      console.error('Subscription creation failed:', error);
    }
  };

  // Handle Access Code
  const handleAccessCodeChange = (code: string) => {
    subscriptionStore.setAccessCode(code);
    if (code.trim() === 'UNCLEMIKE2024') {
      setDiscountApplied(true);
    } else {
      setDiscountApplied(false);
    }
  };

  // Subscription Options Component
  const SubscriptionOptionSelector: React.FC = () => {
    const options = [
      {
        label: '2.2 lbs (Two 1.1 lb bags for freshness)',
        value: '2.2lbs',
        originalPrice: originalPrices['2.2lbs'],
        discountedPrice: discountedPrices['2.2lbs'],
      },
      {
        label: '5.5 lbs (Five 1.1 lb bags for freshness)',
        value: '5.5lbs',
        originalPrice: originalPrices['5.5lbs'],
        discountedPrice: discountedPrices['5.5lbs'],
      },
    ];

    return (
      <div className="flex flex-col space-y-2 text-brown font-neris-semibold mt-6">
        <span>Subscription Options</span>
        <div className="flex flex-col space-y-2">
          {options.map((option) => (
            <div key={option.value} className="flex items-center space-x-2">
              <input
                type="radio"
                name="subscriptionOption"
                id={option.value}
                value={option.value}
                className="hidden"
                checked={selectedSubscriptionOption === option.value}
                onChange={() => setSelectedSubscriptionOption(option.value as any)}
              />
              <label
                htmlFor={option.value}
                className={`p-2 rounded-full ${
                  selectedSubscriptionOption === option.value ? 'bg-brown' : 'bg-transparent border border-brown'
                } cursor-pointer`}
              ></label>
              <label htmlFor={option.value} className="cursor-pointer flex items-center">
                <span>{option.label}</span>
                <span className="ml-2">
                  {discountApplied ? (
                    <>
                      <span className="line-through">${option.originalPrice}</span>{' '}
                      <span className="text-green font-neris-semibold">
                        ${option.discountedPrice.toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <span>${option.originalPrice}</span>
                  )}
                </span>
              </label>
            </div>
          ))}
        </div>
        {discountApplied && (
          <p className="text-green font-neris-semibold">Special price for Uncle Mike!</p>
        )}
      </div>
    );
  };

  // Delivery Frequency Component
  const DeliveryFrequencySelector: React.FC = () => {
    const frequencies: ('2' | '4' | 'monthly' | '6' | '8')[] = ['2', '4', 'monthly', '6', '8'];

    const increaseFrequency = () => {
      const currentIndex = frequencies.indexOf(frequency);
      const nextIndex = (currentIndex + 1) % frequencies.length;
      setFrequency(frequencies[nextIndex]);
    };

    const decreaseFrequency = () => {
      const currentIndex = frequencies.indexOf(frequency);
      const prevIndex = (currentIndex - 1 + frequencies.length) % frequencies.length;
      setFrequency(frequencies[prevIndex]);
    };

    return (
      <div className="flex flex-col items-start space-y-2 text-brown font-neris-semibold mt-6">
        <span>Delivery Frequency</span>
        <div className="flex items-center space-x-2 bg-brown rounded-full">
          <button
            onClick={decreaseFrequency}
            className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full"
          >
            -
          </button>
          <span className="font-neris-semibold text-gold px-2 w-full text-center">
            {frequency === 'monthly' ? 'Every Month' : `Every ${frequency} Weeks`}
          </span>
          <button
            onClick={increaseFrequency}
            className="font-neris-semibold text-gold w-9 h-7 flex items-center justify-center hover:bg-opacity-90 transition duration-300 ease-in-out rounded-full"
          >
            +
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-gold justify-center space-y-6 p-10 relative">
      <div className="absolute inset-0 z-0 overflow-hidden">
        {/* Main background image */}
        <img
          src="https://silver-stream-bucket.s3.amazonaws.com/product_cute_1.svg"
          alt="Coffee plant drawing"
          className="absolute md:mb-0 bottom-[-30%] w-[50%] h-auto lg:-right-20 md:-right-30 sm:-right-10"
        />
      </div>

      <div className="flex flex-col md:flex-row space-x-0 md:space-x-20 w-full md:w-3/5 mx-auto space-y-6 md:space-y-0">
        {/* Product Images */}
        <div className="w-full md:w-1/2 relative z-20">
          {/* Main product image */}
          <img
            src="https://silver-stream-bucket.s3.us-west-2.amazonaws.com/silver_-5.webp"
            alt="Mountain Sunrise Coffee Subscription"
            className="rounded shadow-lg mb-4"
          />
          {/* Accent images */}
          <div className="flex space-x-4">
            <img
              src="https://silver-stream-bucket.s3.us-west-2.amazonaws.com/silver_-8.webp"
              alt="Silver Stream Coffee Package on a wooden table"
              className="w-1/3 rounded shadow"
            />
            <img
              src="https://silver-stream-bucket.s3.us-west-2.amazonaws.com/silver_-11.webp"
              alt="Two bags of Mountain Sunrise Coffee"
              className="w-1/3 rounded shadow"
            />
            <img
              src="https://silver-stream-bucket.s3.us-west-2.amazonaws.com/silver_-10.webp"
              alt="Freshly brewed coffee next to beans"
              className="w-1/3 rounded shadow"
            />
          </div>
        </div>

        {/* Product Details and Subscription Form */}
        <div className="flex w-full md:w-4/5 relative z-20">
          <div className="flex flex-col space-y-4 w-full">
            <h1 className="text-2xl font-neris-semibold">Mountain Sunrise Coffee Subscription</h1>
            <p className="text-brown">
              Enjoy the finest Mountain Sunrise Whole Bean Coffee delivered to your doorstep regularly.
            </p>

            {/* Display general error message */}
            {!isFormValid && formSubmitAttempted && (
              <div className="text-red-500 mb-4">
                Please fix the errors below before submitting the form.
              </div>
            )}

            {/* Subscription Option and Delivery Frequency */}
            <SubscriptionOptionSelector />
            <DeliveryFrequencySelector />

            {/* Access Code Input */}
            <div className="flex flex-col space-y-2 text-brown font-neris-semibold mt-6">
              {!showAccessCodeField ? (
                <button
                  onClick={() => setShowAccessCodeField(true)}
                  className="text-brown underline focus:outline-none"
                >
                  I have a code I want to enter
                </button>
              ) : (
                <>
                  <label>
                    <span>Access Code:</span>
                    <input
                      type="text"
                      value={subscriptionStore.accessCode}
                      onChange={(e) => handleAccessCodeChange(e.target.value)}
                      className="mt-1 block w-full bg-transparent border border-brown rounded-full px-4 py-2"
                    />
                  </label>
                </>
              )}
            </div>

            {/* Subscription Start Date */}
            <div className="flex flex-col space-y-2 text-brown font-neris-semibold mt-6">
              <span>Subscription Start Date:</span>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                minDate={minStartDate}
                dateFormat="yyyy-MM-dd"
                className={`mt-1 block w-full bg-transparent border ${
                  errors.startDate && (dateTouched || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                } rounded-full px-4 py-2`}
              />
              {errors.startDate && (dateTouched || formSubmitAttempted) && (
                <span className="text-red-500">{errors.startDate}</span>
              )}
            </div>

            {/* Shipping Address */}
            <h2 className="text-xl font-neris-semibold mt-6">Shipping Address</h2>
            <div className="flex flex-col space-y-2 text-brown font-neris-semibold mt-4">
              {/* Full Name */}
              <label>
                <span>Full Name:</span>
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  value={address.fullName}
                  onChange={(e) => handleInputChange('fullName', e.target.value)}
                  className={`mt-1 block w-full bg-transparent border ${
                    errors.fullName && (touchedFields.fullName || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                  } rounded-full px-4 py-2`}
                  aria-invalid={errors.fullName ? 'true' : 'false'}
                  aria-describedby={errors.fullName ? 'fullNameError' : undefined}
                />
                {errors.fullName && (touchedFields.fullName || formSubmitAttempted) && (
                  <span id="fullNameError" className="text-red-500">
                    {errors.fullName}
                  </span>
                )}
              </label>

              {/* Address Line 1 */}
              <label>
                <span>Address Line 1:</span>
                <input
                  type="text"
                  name="address-line1"
                  autoComplete="address-line1"
                  value={address.addressLine1}
                  onChange={(e) => handleInputChange('addressLine1', e.target.value)}
                  className={`mt-1 block w-full bg-transparent border ${
                    errors.addressLine1 && (touchedFields.addressLine1 || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                  } rounded-full px-4 py-2`}
                  aria-invalid={errors.addressLine1 ? 'true' : 'false'}
                  aria-describedby={errors.addressLine1 ? 'addressLine1Error' : undefined}
                />
                {errors.addressLine1 && (touchedFields.addressLine1 || formSubmitAttempted) && (
                  <span id="addressLine1Error" className="text-red-500">
                    {errors.addressLine1}
                  </span>
                )}
              </label>

              {/* Address Line 2 - optional */}
              <label>
                <span>Address Line 2:</span>
                <input
                  type="text"
                  name="address-line2"
                  autoComplete="address-line2"
                  value={address.addressLine2}
                  onChange={(e) => handleInputChange('addressLine2', e.target.value)}
                  className="mt-1 block w-full bg-transparent border border-brown rounded-full px-4 py-2"
                />
              </label>

              {/* City */}
              <label>
                <span>City:</span>
                <input
                  type="text"
                  name="address-level2"
                  autoComplete="address-level2"
                  value={address.city}
                  onChange={(e) => handleInputChange('city', e.target.value)}
                  className={`mt-1 block w-full bg-transparent border ${
                    errors.city && (touchedFields.city || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                  } rounded-full px-4 py-2`}
                  aria-invalid={errors.city ? 'true' : 'false'}
                  aria-describedby={errors.city ? 'cityError' : undefined}
                />
                {errors.city && (touchedFields.city || formSubmitAttempted) && (
                  <span id="cityError" className="text-red-500">
                    {errors.city}
                  </span>
                )}
              </label>

              {/* State or Region */}
              <label>
                <span>State or Region:</span>
                <input
                  type="text"
                  name="address-level1"
                  autoComplete="address-level1"
                  value={address.stateOrRegion}
                  onChange={(e) => handleInputChange('stateOrRegion', e.target.value)}
                  className={`mt-1 block w-full bg-transparent border ${
                    errors.stateOrRegion && (touchedFields.stateOrRegion || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                  } rounded-full px-4 py-2`}
                  aria-invalid={errors.stateOrRegion ? 'true' : 'false'}
                  aria-describedby={errors.stateOrRegion ? 'stateOrRegionError' : undefined}
                />
                {errors.stateOrRegion && (touchedFields.stateOrRegion || formSubmitAttempted) && (
                  <span id="stateOrRegionError" className="text-red-500">
                    {errors.stateOrRegion}
                  </span>
                )}
              </label>

              {/* Postal Code */}
              <label>
                <span>Postal Code:</span>
                <input
                  type="text"
                  name="postal-code"
                  autoComplete="postal-code"
                  value={address.postalCode}
                  onChange={(e) => handleInputChange('postalCode', e.target.value)}
                  className={`mt-1 block w-full bg-transparent border ${
                    errors.postalCode && (touchedFields.postalCode || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                  } rounded-full px-4 py-2`}
                  aria-invalid={errors.postalCode ? 'true' : 'false'}
                  aria-describedby={errors.postalCode ? 'postalCodeError' : undefined}
                />
                {errors.postalCode && (touchedFields.postalCode || formSubmitAttempted) && (
                  <span id="postalCodeError" className="text-red-500">
                    {errors.postalCode}
                  </span>
                )}
              </label>

              {/* Country */}
              <label>
                <span>Country:</span>
                <input
                  type="text"
                  name="country"
                  autoComplete="country"
                  value={address.country}
                  onChange={(e) => handleInputChange('country', e.target.value)}
                  className={`mt-1 block w-full bg-transparent border ${
                    errors.country && (touchedFields.country || formSubmitAttempted) ? 'border-red-500' : 'border-brown'
                  } rounded-full px-4 py-2`}
                  aria-invalid={errors.country ? 'true' : 'false'}
                  aria-describedby={errors.country ? 'countryError' : undefined}
                />
                {errors.country && (touchedFields.country || formSubmitAttempted) && (
                  <span id="countryError" className="text-red-500">
                    {errors.country}
                  </span>
                )}
              </label>
            </div>

            {subscriptionStore.message && (
              <p className="mt-4 text-red-500">{subscriptionStore.message}</p>
            )}

            <button
              onClick={handleSubscribe}
              className="mt-6 px-6 py-2 bg-green text-gold font-neris-light rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown"
              disabled={subscriptionStore.isLoading}
            >
              {subscriptionStore.isLoading ? 'Processing...' : 'Subscribe Now'}
            </button>
          </div>

          {/* Include Leaves component for branding */}
          <Leaves />
        </div>
      </div>
    </div>
  );
});

export default SubscriptionPage;