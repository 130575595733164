// src/components/SubscriptionPage/CancelPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Leaves } from '../Products/Components'; // Adjust import path as necessary

const CancelPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center min-h-screen bg-gold p-8">
      <div className="relative">
        <Leaves />
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-neris-semibold text-brown mb-4">
          Subscription Canceled
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-8">
          It looks like you canceled the subscription process. If you have any questions or need assistance, feel free to{' '}
          <Link to="/contact-us" className="text-green underline">contact us</Link>.
        </p>
        <Link
          to="/subscription"
          className="px-6 py-2 bg-green text-gold font-neris-light rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out"
        >
          Try Again
        </Link>
      </div>
    </div>
  );
};

export default CancelPage;